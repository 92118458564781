import { font, margin, text } from '@prototyp/gatsby-plugin-gumball/utils';
import { SEO } from 'components';
import { PageProps } from 'gatsby';
import React from 'react';
import { contactStyles } from '../style/Contact.styles';

const ContactPage: React.FC<PageProps> = ({ location }) => {
  return (
    <div css={contactStyles.root}>
      <SEO title={'DD Moto Kontakt'} href={location.href} />
      <div>
        <div css={[margin.top.lrg]}>
          <p css={[font.size.medium, text.color.textLight]}>Radno vrijeme:</p>
          <p css={[font.weight.bold, font.size.large]}>PONEDJELJAK - PETAK</p>
          <p css={[font.weight.bold, font.size.large]}>10:00 - 15:00</p>
          <p css={[font.size.medium, text.color.textLight]}>
            ili po prethodnom telefonskom dogovoru
          </p>
          <p css={[font.size.large, text.color.textLight, margin.top.huge]}>
            Reisnerova 138, 31000 Osijek
          </p>
          <p css={[font.size.large, font.weight.black]}>
            subota, nedjelja, blagdani -{' '}
            <span css={text.color.primary}>NE RADIMO</span>
          </p>
        </div>
        <div css={[margin.top.huge]}>
          <a css={contactStyles.mail} href={'mailto:info@ddmoto.hr'}>
            info@ddmoto.hr
          </a>
        </div>
        <div css={[margin.top.lrg, margin.bottom.huge]}>
          <a css={contactStyles.number} href="tel:0955295829">
            +385 (0)95 529 5829
          </a>
        </div>
      </div>
      <div>
        <p css={[font.size.large, text.color.textLight]}>
          Kao garanciju za kvalitetu posjedujemo dugogodišnje iskustvo u
          servisiranju (od 2014. godine do 2020. poslovali kao Skuter centar
          Osijek), kao i preko 10 godina iskustva u nastupanju na utrkama
          Prvenstva Hrvatske. Od značajnijih rezultata su titula viceprvaka i
          prvaka u kategoriji Skuteri - Open, te treće mjesto u amaterskoj ligi
          Motocross/Supercross.
        </p>
      </div>
    </div>
  );
};

export default ContactPage;
