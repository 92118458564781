import { css } from '@emotion/react';
import { font, text } from '@prototyp/gatsby-plugin-gumball/utils';
import { breakpoints } from 'style/variables';
import { wrapper } from 'style/wrappers.styles';

const root = css`
  ${wrapper.medium}
  padding-top: 70px;
  display: grid;
  grid-template-columns: 1fr;
  place-items: center;
  grid-column-gap: 50px;

  @media ${breakpoints.contact} {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 25px;
  }

  @media ${breakpoints.medium} {
    grid-column-gap: 100px;
  }
`;

const mail = css`
  ${text.color.primary}
  ${font.size.xxxLarge}

  @media ${breakpoints.contact} {
    ${font.size.huge}
  }
`;

const number = css`
  ${text.color.primary}
  ${font.size.xLarge}

  @media ${breakpoints.contact} {
    ${font.size.xxLarge}
  }
`;

export const contactStyles = {
  root,
  mail,
  number,
};
